import * as universal from "./universal.js";

import * as database from "./dbInterface";
import { auth } from "./dbInterface";

import * as loginUI from "./loginUI.js";
import { onAuthStateChanged } from "firebase/auth";

// Import stylesheets
import "/css/landing.scss";

database.main().then(configUI);

const loginButton = document.getElementById("loginButton");

const openButton = document.getElementById("openButton");

function configUI() {
  loginUI.config("#firebaseui-auth-container", loginButton, auth);

  loginButton.addEventListener("click", () => {
    navigateOnSuccessfulLogin = true;
  });

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // Logged in
      if (navigateOnSuccessfulLogin) {
        window.location.assign("/home.html");
      }
      // openButton.style.display = "block";
      openButton.style.pointerEvents = "auto";
      openButton.style.opacity = 1;
    } else {
      openButton.style.opacity = 0;
      openButton.style.pointerEvents = "none";
      // Animation takes 400ms
      // setTimeout(() => {
      //   openButton.style.display = "none";
      // }, 400);
    }
  });
}

// Whether to go on home.html if you're logged in.
// Should only auto redirect if you logged in from index.html —— if you're logged in, you should still be able to see the page.
// You'll only be whisked away if you specifically click the login button on this page.
var navigateOnSuccessfulLogin = false;

// Quick Pop form
const form = document.getElementById("quickForm");
const quickBarInput = document.getElementById("quickBarInput");

form.addEventListener("submit", async (e) => {
  // Prevent the default form redirect
  // e.preventDefault();

  let text = quickBarInput.value;

  if (text == "") {
    return;
  }
  let isURL = universal.isValidHttpUrl(text);

  if (isURL) {
    // Create short url
    form.action = "/create";
  } else {
    // quick code for redirect!
    form.action = "/go";
  }

  // console.log("Text, isURL, action", text, isURL, form.action);
  return true;
});
